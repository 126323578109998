import { Descriptor } from "hybrids";

export default function mediaQuery<E>(
  selector: string
): Descriptor<E, boolean> {
  const mq = window.matchMedia(selector);

  return {
    get: () => mq.matches,
    connect(host, key, invalidate) {
      invalidate();

      if (mq.addEventListener) {
        mq.addEventListener("change", invalidate as () => {});
        return () => {
          mq.removeEventListener("change", invalidate as () => {});
        };
      }

      mq.addListener(invalidate as () => {});
      return () => mq.removeListener(invalidate as () => {});
    },
  };
}
