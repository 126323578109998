import { define, html, router, store } from "hybrids";

import layout from "~/factories/layout";
import ArtistVideo from "~/stores/ArtistVideo";
import Course from "~/stores/Course";
import Tag from "~/stores/Tag";
import User from "~/stores/User";

import TaggedCreators from "./TaggedCreators";
import TaggedUpcomingEvents from "./TaggedUpcomingEvents";
import TaggedRecentEvents from "./TaggedRecentEvents";
import VideoList, { List } from "~/modules/website/views/VideoList";

interface VHomeTagMainView {
  tagObject: Tag;
  upcomingEvents: Course[];
  recentEvents: Course[];
  taggedVideos: ArtistVideo[];
  taggedCreators: User[];
  featuredTags: Tag[];
}

export default define<VHomeTagMainView>({
  [router.connect]: {
    url: "/tags/:tagObject",
    stack: [VideoList]
  },
  tagObject: store(Tag),
  tag: "v-home-tag-main-view",
  upcomingEvents: store([Course], { id: ({ tagObject }) => ({ page: 1, tag: tagObject.id, past: false }) }),
  recentEvents: store([Course], { id: ({ tagObject }) => ({ page: 1, tag: tagObject.id, past: true }) }),
  taggedVideos: store([ArtistVideo], { id: ({ tagObject }) => ({ page: 1, tag: tagObject.id }) }),
  taggedCreators: store([User], { id: ({ tagObject }) => ({ page: 1, filter: "artists", tag: tagObject.id }) }),
  featuredTags: store([Tag], { id: () => ({ featured: true }) }),
  render: layout("block"),
  content: ({
    tagObject,
    upcomingEvents,
    recentEvents,
    taggedVideos,
    taggedCreators,
    featuredTags
  }) => html`
    ${store.ready(tagObject) &&
    html`
      <v-home-section back>
        <v-ui-text variant="h1" weight="black" xgutter="2">
          ${tagObject.featured && "Explore"}
          ${tagObject.name}
        </v-ui-text>
        ${tagObject.description &&
        html`
          <v-ui-text color="text-secondary" ygutter="2" xgutter="2">
            ${tagObject.description}
          </v-ui-text>`}
      </v-home-section>
      ${store.ready(taggedVideos) &&
      !!taggedVideos.length &&
      html`
        <v-home-section>
          <v-ui-text variant="h3" weight="bold">
            Featured videos
          </v-ui-text>
          <v-home-course-preview
            courses="${taggedVideos}"
            href="${router.url(VideoList, { listName: List.TAGGED, listId: tagObject.id })}"
            limit="9"
          ></v-home-course-preview>
        </v-home-section>`}
      ${store.ready(upcomingEvents) &&
      !!upcomingEvents.length &&
      html`
        <v-home-section>
          <v-ui-text variant="h3" weight="bold">
            Upcoming events
          </v-ui-text>
          <v-home-course-preview
            courses="${upcomingEvents}"
            href="${router.url(TaggedUpcomingEvents, { tagObject: tagObject })}"
            limit="9"
          ></v-home-course-preview>
        </v-home-section>`}
      ${store.ready(recentEvents) &&
      !!recentEvents.length &&
      html`
        <v-home-section>
          <v-ui-text variant="h3" weight="bold">
            Recent events
          </v-ui-text>
          <v-home-course-preview
            courses="${recentEvents}"
            href="${router.url(TaggedRecentEvents, { tagObject: tagObject })}"
            limit="9"
          ></v-home-course-preview>
        </v-home-section>`}
      ${store.ready(taggedCreators) &&
      !!taggedCreators.length &&
      html`
        <v-home-section>
          <v-ui-text variant="h3" weight="bold">
            Creators
          </v-ui-text>
          <v-home-artists artists="${taggedCreators.slice(0, 12)}"></v-home-artists>
          ${taggedCreators.length > 12 &&
          html`
            <v-ui-flexbox layout="center" slot="cta">
              <v-ui-button
                name="See more"
                type="cta"
                color="primary"
                href="${router.url(TaggedCreators, { tagObject: tagObject })}"
              ></v-ui-button>
            </v-ui-flexbox>`}
        </v-home-section>
      `}
    `}
    ${!store.ready(tagObject) &&
    html`
      <v-home-section back>
        <v-ui-text variant="h1" weight="black" xgutter="1">
          Oops! This tag doesn't exist. Try one of these!
        </v-ui-text>
      </v-home-section>
      <v-home-section>
        <v-ui-flexbox layout="center" flow="row wrap" gap="small">
          ${store.ready(featuredTags) && featuredTags.map(
            (featuredTag) => html`
              <v-ui-button
                name="${featuredTag.name}"
                type="cta"
                color="primary"
                href="/tags/${featuredTag.id}"
              ></v-ui-button>
            `
          )}
        </v-ui-flexbox>
      </v-home-section>
    `}
  `,
});
