import { define, html, router, store } from "hybrids";

import layout from "~/factories/layout";
import ArtistVideo from "~/stores/ArtistVideo";
import { PER_PAGE } from "~/stores/Course";
import Tag from "~/stores/Tag";

// A utility function for formatting the list title
const capitalize = (word: string) => {
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
}

// Represents the available lists,
// so users can know what are the available lists.
export enum List {
  FAVORITES = "favorites",
  ONDEMAND  = "ondemand",
  TAGGED    = "tagged",
}

// Maps each list type, to the required parameter,
// those parameters may be required by the server
// or the ArtistVideo model, to make the request.
const listQueryParams = new Map<List, ((listId: string) => any | any)>([
  [List.FAVORITES, (listId) => ({
    liked: true,
    user_id: listId,
  })],
  [List.ONDEMAND, {}],
  [List.TAGGED, (listId) => ({
    tag: listId,
  })]
]);

const emptyListMessage = new Map<List, string>([
  [List.FAVORITES, "You don't have any favorite videos yet. Click the heart on videos to add them to your favorites."],
  [List.TAGGED, ""],
  [List.ONDEMAND, ""],
]);

// Simplifys the params retrieval from the listQueryParams map
const getListQueryParams = (listName, listId = "") => {
  if (listId) {
    return listQueryParams.get(listName)(listId);
  }
  return listQueryParams.get(listName);
}

interface VVideoListMainView {
  page: number;
  listName: List;
  listId: string;
  tagObject: Tag;
  videosList: ArtistVideo[];
}

export default define<VVideoListMainView>({
  [router.connect]: {
    url: "/videos/:listName?listId",
  },
  tag: "v-video-list-view",
  page: 1,
  listName: "",
  listId: "",
  tagObject: store(Tag, { id: "listId" }),
  videosList: store([ArtistVideo], {
    id: ({ page, listName, listId }) => ({
      page: page,
      ...(getListQueryParams(listName, listId))
    })
  }),
  title: ({ listName, tagObject }) => {
    if (store.ready(tagObject)) {
      if (tagObject.featured) return `Explore ${tagObject.name}`;
      return tagObject.name;
    } else if (listName == List.ONDEMAND) {
      return "Videos";
    }
    return capitalize(listName);
  },
  render: layout("block"),
  content: ({ page, listName, listId, tagObject, title, videosList }) => html`
    <v-home-section back>
      <v-ui-text variant="h1" weight="black" ygutter="1" xgutter="1">
        ${title}
      </v-ui-text>
      ${store.ready(tagObject) && tagObject.description && html`
        <v-ui-text color="text-secondary" ygutter="2" xgutter="2">
          ${tagObject.description}
        </v-ui-text>`}
      ${store.ready(tagObject) && html`
        <v-ui-text variant="h3" weight="bold">
          Featured videos
        </v-ui-text>`}
    </v-home-section>

    ${store.ready(videosList) && videosList.length > 0 && html`
      <v-home-grid pending="${store.pending(videosList)}">
        ${videosList.map((video) => html`
          <v-home-course-card
            course="${video}"
            session="${false}"
          ></v-home-course-card>`.key(video.id))}
      </v-home-grid>`}

    ${store.ready(videosList) && videosList.length === 0 && html`
      <v-empty-video-list message=${emptyListMessage.get(listName)}>
      </v-empty-video-list>`}

    <v-home-pagination
      page="${page}"
      count="${store.ready(videosList) ? videosList.length : 0}"
      limit="${PER_PAGE}"
    ></v-home-pagination>
  `
});
