import { store, Model } from "hybrids";
import { get } from "~/utils/api";

interface Tag {
  id: string;
  name: string;
  description: string;
  featured: boolean;
}

const Tag: Model<Tag> = {
  id: true,
  name: "",
  description: "",
  featured: false,
  [store.connect]: {
    get: (id) => get("/tags/:id", id),
    list(query) {
      return get("/tags", query);
    },
    offline: 1000 * 60 * 60 * 24 * 7, // 7 days
  },
};

export default Tag;
