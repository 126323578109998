import { define, html, store, router } from "hybrids";
import layout from "~/factories/layout";
import Tag from "~/stores/Tag";
import TagMain from "./TagMain";
import User from "~/stores/User";

interface VHomeTaggedCreatorsView {
  artists: User[];
  tagObject: Tag;
}

export default define<VHomeTaggedCreatorsView>({
  [router.connect]: { url: "/tags/:tagObject/creators" },
  tag: "v-home-tagged-creators-view",
  tagObject: store(Tag),
  artists: store([User], { id: ({ tagObject }) => ({ filter: "artists", tag: tagObject.id }) }),
  render: layout("flex"),
  content: ({ tagObject, artists }) => html`
    <v-home-section back backUrl="${router.url(TagMain, { tagObject: tagObject })}">
      <v-ui-text variant="h1" weight="black" ygutter="1" xgutter="1">
        ${tagObject.featured && "Explore"}
        ${tagObject.name}
      </v-ui-text>
      ${tagObject.description &&
      html`<v-ui-text color="text-secondary" ygutter="2" xgutter="2">
        ${tagObject.description}
      </v-ui-text>`}
      <v-ui-text variant="h3" weight="bold">
        Creators
      </v-ui-text>
      <v-home-artists artists="${artists}"></v-home-artists>
    </v-home-section>
  `,
});
