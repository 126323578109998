import { define, html, store, router } from "hybrids";
import Course, { PER_PAGE } from "~/stores/Course";
import Tag from "~/stores/Tag";
import TagMain from "./TagMain";

interface VHomeTaggedUpcomingEventsView {
  page: number;
  courses: Course[];
  tagObject: Tag;
}

export default define<VHomeTaggedUpcomingEventsView>({
  [router.connect]: { url: "/tags/:tagObject/upcoming" },
  tag: "v-home-tagged-upcoming-events-view",
  page: 1,
  tagObject: store(Tag),
  courses: store([Course], { id: ({ tagObject, page }) => ({ page: page, tag: tagObject.id, past: false }) }),
  content: ({ tagObject, courses, page }) => html`
    <v-home-section back backUrl="${router.url(TagMain, { tagObject: tagObject })}">
      <v-ui-text variant="h1" weight="black" ygutter="1" xgutter="1">
        ${tagObject.featured && "Explore"}
        ${tagObject.name}
      </v-ui-text>
      ${tagObject.description &&
       html`<v-ui-text color="text-secondary" ygutter="2" xgutter="2">
        ${tagObject.description}
      </v-ui-text>`}
      <v-ui-text variant="h3" weight="bold">
        Upcoming events
      </v-ui-text>
      <v-home-grid pending="${store.pending(courses)}">
        ${store.ready(courses) &&
        courses.map((course) =>
          html`<v-home-course-card
            course="${course}"
            session="${false}"
          ></v-home-course-card>`.key(course.id)
        )}
      </v-home-grid>
      <v-home-pagination
        page="${page}"
        count="${store.ready(courses) ? courses.length : 0}"
        limit="${PER_PAGE}"
      ></v-home-pagination>
    </v-home-section>
  `,
});
